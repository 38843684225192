<template>

<!-- 校园筛查 筛查前设置 账户管理 新增-->
  <div class="page">
    <div class="account-form">
      <el-form label-width="100px" :model="ruleForm" :rules="rules" ref="ruleForm" size="mini"
               class="account-ruleForm el-form">
        <el-form-item label="姓名：" prop="sysName">
          <el-input v-model="ruleForm.sysName" placeholder="请填写姓名"
                    maxlength="10"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机：" prop="phone">
          <el-input maxlength="11" v-model="ruleForm.phone" placeholder="请填写手机"></el-input>
        </el-form-item>
        <el-form-item label="角色：" prop="roleType">
          <el-select v-model="ruleForm.roleType" placeholder="请选择角色">
            <el-option
              v-for="item in roleTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学校：" prop="schoolId" v-show="ruleForm.roleType=='10'">
          <el-select v-model="ruleForm.schoolId" filterable :filter-method="searchSchool" @focus="changeSchool" @change="changeSchool" placeholder="请选择学校">
            <el-option
              v-for="item in schoolOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
<!--        :prop="ruleForm.roleType=='11'?'screeningListId':''"-->
        <!-- <el-form-item label="筛查计划："

          prop="screeningListId"
          v-show="ruleForm.roleType=='11'">
          <el-select v-model="ruleForm.screeningListId" multiple filterable @focus="changeSelect" @change="changeSelect" placeholder="请选择筛查计划">
            <el-checkbox v-model="checked" @change='selectAll' style="text-align: right;width: 100%;padding-right: 10px;box-sizing: border-box;line-height: 32px;">全选</el-checkbox>
            <el-option
              v-for="item in screeningOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <div class="tipss">
            tips：创建账号后请将密码复制到其它区域保存，该系统为了保证账号安全，只可联系平台管理员修改，平台管理员微信：<span class="forestgreen">jintian5453</span>
          </div>
        </el-form-item>
      </el-form>
      <div class="from-foot">
        <el-button type="return"  @click="returnPage()">返回</el-button>
        <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading"
                   @click="submitForm('ruleForm')">保存
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import sysService from '@/globals/service/detection/Sys.js'
import schoolService from '@/globals/service/detection/school.js'
import selectService from '@/globals/service/detection/select.js'
// import screeningService from '@/globals/service/detection/screening.js'
export default {
  data () {
    const checkPhone = (rule, value, callback) => {
      const reg = /^1[345789]\d{9}$/
      if (!reg.test(value)) {
        callback(new Error('请输入11位手机号'))
      } else {
        callback()
      }
    }

    return {
      fullscreenLoading: false,
      checked: false,
      roleTypeOptions: [
        {
          value: '11',
          label: '筛查员'
        },
        {
          value: '10',
          label: '学校管理员'
        }
      ],
      schoolOptions: [],
      screeningOptions: [],
      startPage: 1,
      pageSize: 1000,
      ruleForm: {
        sysName: '',
        phone: '',
        roleType: '',
        schoolId: '',
        screeningListId: []
      },

      rules: {
        sysName: [
          { required: true, message: '请填写姓名', trigger: 'blur' },
          { min: 1, max: 10, message: '请填写1~10个字符', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请填写手机', trigger: 'blur' },
          { type: 'number', validator: checkPhone, message: '请输入正确的11位手机号', trigger: ['blur', 'change'] }
        ],
        roleType: [
          { required: true, message: '请选择角色', trigger: 'change' }
        ],
        schoolId: [
          { required: true, validator: this.checkSchoolId, message: '请选择学校', trigger: 'change' }
        ],
        screeningListId: [
          { required: true, validator: this.checkscreeningListId, message: '请选择筛查计划', trigger: 'change' }
        ]
      }
    }
  },
  mounted () {
    this.getSchoolSelect()
    // this.getScreeningSelect()
  },
  created () {
  },
  methods: {
    goTo (path, query) {
      this.$router.push({ path: path, query: query })
    },
    checkSchoolId (rule, value, callback) {
      rule.required = false
      const roleType = this.ruleForm.roleType
      if (roleType === 10 && value.length === 0) {
        rule.required = true
        callback(new Error('请选择学校'))
      } else {
        callback()
      }
    },
    checkscreeningListId (rule, value, callback) {
      rule.required = false
      const roleType = this.ruleForm.roleType
      if (roleType === 11 && value.length === 0) {
        rule.required = true
        callback(new Error('请选择筛查计划'))
      } else {
        callback()
      }
    },
    selectAll () {
      this.ruleForm.screeningListId = []
      if (this.checked) {
        this.screeningOptions.map((item) => {
          this.ruleForm.screeningListId.push(item.value)
        })
      } else {
        this.ruleForm.screeningListId = []
      }
    },
    changeSelect (val) {
      // this.searchScreening('')
      if (val.length === this.screeningOptions.length) {
        this.checked = true
      } else {
        this.checked = false
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        // return false
        if (valid) {
          const that = this
          const ruleForm = this.ruleForm
          that.fullscreenLoading = true
          if (ruleForm.roleType === '10') {
            ruleForm.screeningListId = []
            if (!ruleForm.schoolId) {
              that.fullscreenLoading = false
              return this.$message.error('请填入选择关联学校！')
            }
          } else if (ruleForm.roleType === '11') {
            ruleForm.schoolId = ''
            that.fullscreenLoading = false
            // if (!ruleForm.screeningListId.length) {
            //   that.fullscreenLoading = false
            //   return this.$message.error('请填入选择关联计划！')
            // }
          }
          sysService.add(ruleForm).then(res => {
            sessionStorage.setItem('accountAddInfo', JSON.stringify({ msg: '账户创建成功', data: res.data }))
            this.$message({
              message: res.errMsg,
              type: 'success',
              duration: 1000,
              onClose: function () {
                that.fullscreenLoading = false
                that.$router.push({ path: '/detection/accountManagement' })
              }
            })
          }).catch(() => {
            that.fullscreenLoading = false
          })
        } else {
          this.$message.error('请填入相应信息！')
          return false
        }
      })
    },
    returnPage () {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/login' })
        return false
      } else {
        this.$router.push({ path: '/detection/accountManagement' })
      }
    },
    changeSchool () {
      this.searchSchool('')
    },
    searchSchool (row) {
      schoolService.search({ name: row }).then(res => {
        const schoolList = res.data || []
        const schoolOptions = []
        schoolList.forEach(item => {
          schoolOptions.push({
            value: item.id,
            label: item.schoolName
          })
        })
        this.schoolOptions = schoolOptions
      })
    },
    getSchoolSelect () {
      selectService.school({ startPage: this.startPage, pageSize: this.pageSize }).then(res => {
        const schoolList = res.data.schoolList
        const schoolOptions = []
        schoolList.forEach(item => {
          schoolOptions.push({
            value: item.id,
            label: item.schoolName
          })
        })
        this.schoolOptions = schoolOptions
      })
    }
    // getScreeningSelect () {
    //   selectService.screening({
    //     type: 0,
    //     startPage: this.startPage,
    //     pageSize: this.pageSize
    //   }).then(res => {
    //     const list = res.data.list
    //     const screeningOptions = []
    //     list.forEach(item => {
    //       screeningOptions.push({
    //         value: item.id,
    //         label: item.screeningName
    //       })
    //     })
    //     this.screeningOptions = screeningOptions
    //   })
    // }
    // searchScreening (row) {
    //   screeningService.search({ name: row }).then(res => {
    //     const list = res.data || []
    //     const screeningOptions = []
    //     list.forEach(item => {
    //       screeningOptions.push({
    //         value: item.id,
    //         label: item.screeningName
    //       })
    //     })
    //     this.screeningOptions = screeningOptions
    //   })
    // }
  }

}
</script>
<style lang="scss" scoped>
.account-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px 0;
  box-sizing: border-box;
  max-width: 500px;
}
.header-container{
  width: 100%;
  height: 43px;
  border-bottom: 1px solid #f5f5f5;
  .header-section{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .route-name{
      line-height: 43px;
      font-size: 12px;
      color: #333;
    }
    .button-section{
      display: flex;
      align-items: center;
    }
  }
}
.page{
    padding: 20px 10px;
    min-height: 100%;
    background-color: #fff;
  }
  .tipss{
    color: #333;
  }
</style>
